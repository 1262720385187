import axios, { AxiosInstance, AxiosRequestConfig } from "axios";

import {
    setToken,
    setRefreshToken,
    getToken,
    getTokenKey,
    getRefreshToken,
    getRefreshTokenKey,
    removeToken,
} from "../plugins/cookie";

export class Interceptors {
    instance: AxiosInstance;

    constructor() {
        this.instance = axios.create({
          baseURL: "",
          timeout: 10000,
          method: 'get,post,put,patch,delete'
        });

        this.init();
    }

    init() {
        // 请求接口拦截器
        this.instance.interceptors.request.use(
            (config:AxiosRequestConfig) => {
                if(config.method == 'post'){
                    // 判断一下是否有cookie 如果有的话则把cookie放入请求头中
                    if (getToken() && config && config.headers) {
                        config.headers[getTokenKey()] = getToken();
                        config.headers[getRefreshTokenKey()] = getRefreshToken();
                    }
                }

                const token = localStorage.getItem('authorization');
                if(token){
                    if(config && config.headers){
                        let _headers:any = config.headers;
                        _headers['Authorization'] = token;
                        _headers['X-CSRF-TOKEN'] = token;
                        _headers['HTTP_CLIENTID'] = localStorage.getItem('id');
                    }
                }
                return config;
            },
            (err:any) => {
                if (err.message == "interrupt") {
                    console.log('break request');
                    return;
                }
                return Promise.reject(err);
            }
        );

        // 响应拦截器
        this.instance.interceptors.response.use(
            (response) => {
                const res = response.data;
                if (!response.status.toString().startsWith("2") || res.code === -1) {
                    // 如果状态码不是2开头或者接口code返回-1 则是返回错误信息
                    console.error("系统错误, 请检查API是否正常！");
                    return;
                }

                if (res.code !== 0) {
                    if (res.code === -3) {
                        console.error("登录过期");
                        removeToken();
                    } else {
                        if (res.msg && res.msg != "success") {
                            console.error(res.msg);
                        }
                    }
                    return Promise.resolve(response);
                } else {
                    // 返回成功则把token存储一下
                    const headers = response.headers;
                    const token = headers.token;
                    const refresh_token = headers[getRefreshTokenKey()];
                    if (token && refresh_token) {
                        setToken(token);
                        setRefreshToken(refresh_token);
                    }
                    return response;
                }
            },
            (error:any) => {
                /*
                if (error.message === "Request failed with status code 500") {
                    console.error("系统错误，请检查API是否正常！");
                    return;
                }
                */

                if (error.message == "interrupt") {
                    console.log('break request');
                    return;
                } else {
                    let code = -110;
                    if (error && error.response && error.response.status) {
                        code = error.response.status;
                        if (code === 401 || code === -3) {
                            removeToken();
                        }
                    } else {
                        console.error(error.message);
                    }
                    return Promise.resolve(error);
                }
            }
        );   
    }

    getInterceptors() {
        return this.instance;
    }
}